import React, { useState, useEffect, useRef} from 'react';
import { TextField, Select } from '@material-ui/core';
import { debounce } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';

const AccordionSearchBar = ({findTrials, searchVal, tableRowCount=''}) => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  let search = params.get('search');
  if(search){
    searchVal = search
  }
  const handleSearch = function(val){
    const queryParams = new URLSearchParams();
    queryParams.set('search', val);
    if(val){
      history.push({
        pathname: window.location.pathname,
        search: queryParams.toString(),
      });
    }else{
      history.push({
        pathname: window.location.pathname
      });
    }
    findTrials(val)
  }
  return (
    <>
        <div class="input-group mb-3" style={{flexWrap:'inherit'}}>
          <input
            class="form-control search-bar-global mt-2"
            placeholder="Search..."
            onChange={debounce((e) => handleSearch(e.target.value), 1000)}
            variant="outlined"
            style={{ width: '100%' }}
            defaultValue={searchVal}
          />
          {tableRowCount > 0 && <span class="input-group-text mt-2" id="basic-addon2"><b>{tableRowCount > 0?tableRowCount:''}</b></span>}
        </div>
    </>
  );
};

export default AccordionSearchBar;
