import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  getDiseaseDashboardCardById,
  bulkCard,
  cardById,
  sectionUpdate,
  addField,
  addSource,
  searchSource,
  updateSource,
  getUserFeedback,
} from '../apiCalls/backendUrl';

const config = {
  headers: {
    Authorization: `Token 03314be0379da6d92a7ae3e680ab6879f6b00f2c`,
  },
};

const UiReducer = createSlice({
  name: 'ui',
  initialState: {
    name: 'User',
    sidebarShow: 'responsive',
    data: [],
    allData: [],
    newCategoryData: [
      {
        id: 1000,
        title: '',
        subtitle: '',
        condition: '',
        color_code: '',
        sections: [
          {
            title: '',
            subtitle: '',
            fields: [
              {
                title: '',
                value: '',
              },
            ],
          },
        ],
      },
    ],
    diseasesNameAndId: [
      { name: 'Alzheimers', id: '130' },
      { name: 'Asthma', id: '121' },
      { name: 'Atherosclerosis', id: '122' },
      { name: 'Breast Cancer', id: '123' },
      { name: 'COPD', id: '124' },
      { name: 'COVID 19', id: '125' },
      { name: 'Cystic Fibrosis', id: '126' },
      { name: 'Glaucoma', id: '128' },
      { name: 'HIV', id: '120' },
      { name: "Parkinson's", id: '131' },
      { name: 'T2 Diabetes', id: '127' },
      { name: 'test condition', id: '133' },
      { name: 'Wet AMD', id: '129' },
    ],
    categories: [],
    selectedDiseaseId: '',
    selectedCategoryId: '',
    newCategory: false,
    categoryIndex: '',
    oddColumn: [],
    evenColumn: [],
    diseaseDashboardView: 'dashboard',
    sourceModalOpen: false,
    addNewSourceFields: {
      source_url: '',
      title: '',
      name: '',
      publication: '',
      country: '',
      source_date: new Date().toISOString().slice(0, 10),
      study_date: new Date().toISOString().slice(0, 10),
      comment: '',
      score: '',
      pubmed_id: 0,
      no_of_patients: 0,
      preview: '',
      image_url: '',
    },
    sourcesTable: {},
    sourcesTableView: 'search',
    fieldId: '',
    reportId: 0,
    sourceCallback: () => {},
    sourceTitle: '',
    loading: false,
  },
  reducers: {
    setName(state, action) {
      state.name = action.payload;
    },
    setSidebar(state, action) {
      state.sidebarShow =
        state.sidebarShow === 'responsive' ? false : 'responsive';
    },
    setSidebarMobile(state, action) {
      state.sidebarShow =
        state.sidebarShow === 'responsive' ? true : 'responsive';
    },
    AddSection(state, action) {
      console.log(action.payload);
      state.newCategory
        ? (state.newCategoryData[action.payload.parent].sections = [
            ...state.newCategoryData[action.payload.parent].sections,
            {
              title: '',
              subtitle: '',
              fields: [{ title: '', value: '' }],
            },
          ])
        : (state.data[action.payload.parent].sections = [
            ...state.data[action.payload.parent].sections,
            {
              id: action.payload.id,
              title: '',
              subtitle: '',
            },
          ]);
    },
    RemoveSection(state, action) {
      state.newCategory
        ? state.newCategoryData[action.payload.parent].sections.splice(
            action.payload.child,
            1
          )
        : state.data[action.payload.parent].sections.splice(
            action.payload.child,
            1
          );
    },
    AddField(state, action) {
      state.newCategory
        ? (state.newCategoryData[action.payload.parent].sections[
            action.payload.child
          ].fields = [
            ...state.newCategoryData[action.payload.parent].sections[
              action.payload.child
            ].fields,
            {
              title: '',
              value: '',
              sourceValue: '',
            },
          ])
        : (state.data[action.payload.parent].sections[
            action.payload.child
          ].fields = [
            ...state.data[action.payload.parent].sections[action.payload.child]
              .fields,
            {
              title: '',
              value: '',
              sourceValue: '',
            },
          ]);
    },
    RemoveField(state, action) {
      const index = state.newCategory
        ? state.newCategoryData[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].fields.findIndex((x) => x.id == action.payload.index)
        : state.data[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].fields.findIndex((x) => x.id == action.payload.index);

      state.newCategory
        ? state.newCategoryData[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].fields.splice(index, 1)
        : state.data[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].fields.splice(index, 1);
    },
    SetTitle(state, action) {
      state.newCategory
        ? (state.newCategoryData[action.payload.parent].title =
            action.payload.value)
        : (state.data[action.payload.parent].title = action.payload.value);
    },
    SetColorCode(state, action) {
      state.newCategory
        ? (state.newCategoryData[action.payload.parent].color_code =
            action.payload.value)
        : (state.data[action.payload.parent].color_code = action.payload.value);
    },
    SetSectionTitle(state, action) {
      state.newCategory
        ? (state.newCategoryData[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].title = action.payload.value)
        : (state.data[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].title = action.payload.value);
    },
    SetFieldName(state, action) {
      state.newCategory
        ? (state.newCategoryData[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].fields[action.payload.index].title = action.payload.value)
        : (state.data[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].fields[action.payload.index].title = action.payload.value);
    },
    SetFieldValue(state, action) {
      state.newCategory
        ? (state.newCategoryData[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].fields[action.payload.index].value = action.payload.value)
        : (state.data[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].fields[action.payload.index].value = action.payload.value);
    },
    SetFieldSource(state, action) {
      state.data[action.payload.parent.parent].sections[
        action.payload.parent.child
      ].fields[action.payload.index].source = action.payload.value;
    },
    setDiseaseDashboardDataByID(state, action) {
      state.data = [...action.payload];
    },
    setAllDiseaseDashboardDataByID(state, action) {
      state.oddColumn = [];
      state.evenColumn = [];
      state.allData = [...action.payload];
      action.payload.map((item, index) => {
        if (index % 2 !== 0) {
          return state.oddColumn.push(action.payload[index]);
        } else {
          return state.evenColumn.push(action.payload[index]);
        }
      });
    },
    setListOfCategories(state, action) {
      state.categories = [...state.categories, action.payload];
      state.newCategory = false;
    },
    setDiseaseId(state, action) {
      state.selectedDiseaseId = action.payload;
    },
    setCategoryId(state, action) {
      state.selectedCategoryId = action.payload;
      state.newCategory = false;
    },
    setCategoryIndex(state, action) {
      state.categoryIndex = action.payload;
    },
    setNewCategory(state, action) {
      state.newCategory = !state.newCategory;
      state.selectedCategoryId = '';
    },
    clearCategory(state, action) {
      state.categories = [];
      state.selectedCategoryId = '';
    },
    SetSectionSortId(state, action) {
      state.newCategory
        ? (state.newCategoryData[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].sort_id = action.payload.value)
        : (state.data[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].sort_id = action.payload.value);
    },
    SetFieldSortId(state, action) {
      state.newCategory
        ? (state.newCategoryData[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].fields[action.payload.index].sort_id = action.payload.value)
        : (state.data[action.payload.parent.parent].sections[
            action.payload.parent.child
          ].fields[action.payload.index].sort_id = action.payload.value);
    },
    SetCardSortId(state, action) {
      state.newCategory
        ? (state.newCategoryData[action.payload.parent].sort_id =
            action.payload.value)
        : (state.data[action.payload.parent].sort_id = action.payload.value);
    },
    SetDiseaseDashboardView(state, action) {
      state.diseaseDashboardView = action.payload;
    },
    toggleSourceModal(state, action) {
      state.sourceModalOpen = !state.sourceModalOpen;
    },
    toggleSourcesView(state, action) {
      state.sourcesTableView = action.payload;
    },
    setSourcesFormFieldValue(state, action) {
      state.addNewSourceFields[action.payload.fieldTitle] =
        action.payload.fieldValue;
    },
    setSourcesResults(state, action) {
      state.sourcesTable = action.payload;
    },
    setFieldId(state, action) {
      state.fieldId = action.payload;
    },
    setReportId(state, action) {
      state.reportId = action.payload;
    },
    setSourceCallback(state, action) {
      state.sourceCallback = action.payload;
    },
    setSourceLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

const { actions } = UiReducer;

export const {
  setName,
  setSidebar,
  AddSection,
  RemoveSection,
  AddField,
  RemoveField,
  SetTitle,
  SetSectionTitle,
  SetFieldName,
  SetFieldValue,
  setSidebarMobile,
  setDiseaseDashboardDataByID,
  setAllDiseaseDashboardDataByID,
  setDiseaseId,
  setCategoryId,
  setListOfCategories,
  setNewCategory,
  clearCategory,
  setCategoryIndex,
  SetColorCode,
  SetSectionSortId,
  SetFieldSortId,
  SetDiseaseDashboardView,
  SetCardSortId,
  toggleSourceModal,
  toggleSourcesView,
  setSourcesFormFieldValue,
  setSourcesResults,
  setFieldId,
  setReportId,
  setSourceCallback,
  SetFieldSource,
  setSourceLoading,
} = actions;

export const getCardByIDDispatch = (id) => async (dispatch) => {
  try {
    dispatch(setSourceLoading(true));
    const { data } = await Axios.get(
      `${getDiseaseDashboardCardById}?card_id=${id}`,
      config
    );
    dispatch(setDiseaseDashboardDataByID(data.data));
    dispatch(setSourceLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getAllDDCardsDispatch = (id) => async (dispatch) => {
  try {
    const { data } = await Axios.get(
      `${getDiseaseDashboardCardById}?condition_id=${id}`,
      config
    );
    await dispatch(clearCategory());
    await dispatch(setAllDiseaseDashboardDataByID(data.data));
    data.data.map((item, index) => {
      dispatch(setListOfCategories({ name: item.title, id: item.id }));
    });
  } catch (error) {
    console.log(error);
  }
};

export const postCreateBulkCardDispatch =
  (id, cardData) => async (dispatch) => {
    // console.log(cardData[0])
    const final = { ...cardData[0], condition: id };
    delete final.updated_at;
    delete final.created_at;
    delete final.id;

    try {
      console.log(final);
      const { data } = await Axios.post(`${bulkCard}`, final, config);
      console.log(data);
      dispatch(clearCategory());
    } catch (error) {
      console.log(error);
    }
  };

export const deleteCardByIdDispatch = (id, categoryId) => async (dispatch) => {
  try {
    const { data } = await Axios.delete(`${cardById}/${id}`, config);
    dispatch(clearCategory(categoryId));
    // window.location.reload()
  } catch (error) {
    console.log(error);
  }
};

export const addSectionDispatch = (id, parent) => async (dispatch) => {
  try {
    const { data } = await Axios.post(
      `${sectionUpdate}`,
      { card_id: id },
      config
    );
    console.log(data.data.id);
    dispatch(getCardByIDDispatch(id));
  } catch (error) {
    console.log(error);
  }
};

export const deleteSectionDispatch =
  (parent, child, id, cardId) => async (dispatch) => {
    try {
      const { data } = await Axios.delete(`${sectionUpdate}/${id}`, config);
      console.log(data);
      dispatch(getCardByIDDispatch(cardId));
    } catch (error) {
      console.log(error);
    }
  };

export const setTitleDispatch =
  (id, title, selectedCategoryId) => async (dispatch) => {
    console.log(id, title, selectedCategoryId);
    try {
      const { data } = await Axios.put(`${cardById}/${id}`, title, config);
      // dispatch(getCardByIDDispatch(selectedCategoryId));
    } catch (error) {
      console.log(error);
    }
  };

export const setSectionTitleDispatch = (id, title) => async (dispatch) => {
  console.log(id, title);
  try {
    const { data } = await Axios.put(`${sectionUpdate}/${id}`, title, config);
  } catch (error) {
    console.log(error);
  }
};

export const addFieldDispatch = (sectionId, cardId) => async (dispatch) => {
  console.log(sectionId, cardId, 'hi');
  try {
    // dispatch(setSourceLoading(true));
    const { data } = await Axios.post(
      `${addField}`,
      { card_section: sectionId },
      config
    );
    console.log(data);
    dispatch(getCardByIDDispatch(cardId));
    // dispatch(setSourceLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const deleteFieldDispatch = (id, cardId) => async (dispatch) => {
  console.log(id, cardId);
  try {
    const { data } = await Axios.delete(`${addField}/${id}`, config);
    console.log(data, 'hi');
    dispatch(getCardByIDDispatch(cardId));
  } catch (error) {
    console.log(error);
  }
};

export const setFieldTitleDispatch =
  (id, title, selectedCategoryId) => async (dispatch) => {
    console.log(id, title, selectedCategoryId);
    try {
      const { data } = await Axios.put(`${addField}/${id}`, title, config);
      // dispatch(getCardByIDDispatch(selectedCategoryId));
    } catch (error) {
      console.log(error);
    }
  };

export const setFieldValueDispatch =
  (id, title, selectedCategoryId) => async (dispatch) => {
    console.log(id, title, selectedCategoryId);
    try {
      const { data } = await Axios.put(`${addField}/${id}`, title, config);
      // dispatch(getCardByIDDispatch(selectedCategoryId));
    } catch (error) {
      console.log(error);
    }
  };

export const setColorCodeDispatch = (id, colorCode) => async (dispatch) => {
  console.log(id, colorCode);
  try {
    const { data } = await Axios.put(`${cardById}/${id}`, colorCode, config);
    // dispatch(getCardByIDDispatch(id));
  } catch (error) {
    console.log(error);
  }
};

export const SetSectionSortIdDispatch = (id, sort_id) => async (dispatch) => {
  console.log(id, sort_id);
  try {
    const { data } = await Axios.put(`${sectionUpdate}/${id}`, sort_id, config);
  } catch (error) {
    console.log(error);
  }
};

export const SetFieldSortIdDispatch = (id, sort_id) => async (dispatch) => {
  console.log(id, sort_id);
  try {
    const { data } = await Axios.put(`${addField}/${id}`, sort_id, config);
  } catch (error) {
    console.log(error);
  }
};

export const SetCardSortIdDispatch = (id, sort_id) => async (dispatch) => {
  console.log(id, sort_id);
  try {
    const { data } = await Axios.put(`${cardById}/${id}`, sort_id, config);
  } catch (error) {
    console.log(error);
  }
};

export const AddNewSourceDispatch =
  (formData, selectedCategoryId, fieldId) => async (dispatch) => {
    // stats_db = fieldId from this store
    // condition = id
    try {
      const { data } = await Axios.post(`${addSource}`, formData, config);
      console.log(data, fieldId);
      await dispatch(
        UpdateSourceDispatch(
          {
            sourceId: data.id,
            stats_db: fieldId,
          },
          selectedCategoryId
        )
      );
      await dispatch(setFieldId(''));
      await dispatch(getCardByIDDispatch(selectedCategoryId));
    } catch (error) {
      console.log(error);
    }
  };

export const AddNewSourceInReportDispatch =
  (formData, callback) => async (dispatch) => {
    // stats_db = fieldId from this store
    // condition = id
    try {
      const { data } = await Axios.post(
        `${addSource}`,
        formData.sourceData,
        config
      );
      await dispatch(
        UpdateSourceInReportDispatch(
          {
            sourceId: data.id,
            reportId: formData.reportId,
          },
          callback
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

export const SearchSourceDispatch = (searchItem) => async (dispatch) => {
  if (searchItem.length <= 0) {
    return null;
  }

  try {
    const { data } = await Axios.get(
      `${searchSource}/?search=${searchItem}`,
      config
    );
    console.log(data);
    dispatch(setSourcesResults(data));
  } catch (error) {
    console.log(error);
  }
};

export const UpdateSourceDispatch =
  (sourceData, selectedCategoryId) => async (dispatch) => {
    console.log(sourceData);
    try {
      const { data } = await Axios.put(
        `${updateSource}/${sourceData.sourceId}`,
        { condition: sourceData.diseaseId, stats_db: sourceData.stats_db },
        config
      );
      console.log(data, 'src data');
      setTimeout(() => {
        dispatch(getCardByIDDispatch(selectedCategoryId));
      }, 1000);
      dispatch(toggleSourceModal());
    } catch (error) {
      console.log(error);
    }
  };

export const UpdateSourceInReportDispatch =
  (sourceData, callback) => async (dispatch) => {
    console.log(sourceData);
    try {
      const { data } = await Axios.put(
        `${updateSource}/${sourceData.sourceId}`,
        { report: sourceData.reportId },
        config
      );
      callback(data.data);
      console.log(data.data, 'src data');
      dispatch(toggleSourceModal());
    } catch (error) {
      console.log(error);
    }
  };

export const getUserFeedbackDispatch = (feedbackData) => async (dispatch) => {
  try {
    const response = await Axios.post(getUserFeedback, feedbackData, config);
    console.log('Feedback submitted:', response.data);
    // Optionally dispatch an action to update the state
    // dispatch({ type: 'FEEDBACK_SUBMITTED', payload: response.data });
  } catch (error) {
    console.error('Error submitting feedback:', error);
    // Optionally dispatch an error action
    // dispatch({ type: 'FEEDBACK_ERROR', payload: error });
  }
};

export default UiReducer;
