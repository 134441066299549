import React from 'react';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useParams } from 'react-router-dom';
import AllCompetitorsDrug from './AllCompetitorsDrug';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80%',
    maxWidth: '70%',
    backgroundColor: '#fff',
    overflowY: 'auto',
    maxHeight: '80%',
    position: 'relative',
    boxShadow: 'none',
  },
  cards: {
    marginTop: 30,
    padding: 0,
  },
  cardsHeader: {
    display: 'flex',
    background: '#c5ecda',
    fontWeight: 'bold',
    padding: '10px 10px',
    '& h3' : {
      fontSize: 24,
      fontWeight: 'bold',
      margin: 0,
    },
    '& p' : {
      fontSize: 18,
      margin: 0,
    },
  },
  leftCard: {
    width: '70%',
  },
  rightCard: {
    width: '30%',
    textAlign: 'right',
  },
  cardsBody: {
    padding: '10px 10px',
    '& p' : {
      fontSize: 16,
      margin: 0,
    },
  },
  header: {
    textAlign: 'center',
    background: '#2bb670',
    color: '#fff',
    fontWeight: 'bold',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    '& span' : {
      color: '#fff',
    },
  },
  closeBtn: {
    position: 'fixed',
    top: 0,
    right: 0,
    background: '#c5ecda',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#387249',
    padding: '11px 20px',
    cursor: 'pointer',
  },
}));

function CompetitorModal({ item, onClose, category, goToEfficacy }) {
  const { diseaseName } = useParams();
  let listTitle = item['name'];
  // let listItem = [...Object.values(item[1])];
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      className={classes.modal}
      open={true}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.header}
            title={listTitle}
            style={{ zIndex:'9999',}}
          />
          <button className={classes.closeBtn} onClick={handleClose}>x</button>
          <CardContent>
            <AllCompetitorsDrug hideHeader={true} searchValue={listTitle} />
          </CardContent>
        </Card>
      </Fade>
    </Modal>
  );
};

export default CompetitorModal;