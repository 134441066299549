import React, { useState, useEffect } from 'react';
import { CCard, CCardBody, CLabel, CButton, CSubheader } from '@coreui/react';
import './ActiveTrials.css';
import { useDispatch, useSelector } from 'react-redux';
import { SetDiseaseDashboardView } from '../../reducers/UiReducer';
import { Breadcrumbs, Button, Typography } from '@material-ui/core';
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import './Header.scss';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { bookmarkDiseasesPost, bookmarkDiseasesDelete, bookmarkDiseasesChecked } from '../../apiCalls/axiosCall/doctorPatientAxios';

const Header = ({ label, diseasesName, quickNavItem, mainNav='condition' }) => {
  const MenuStore = useSelector((state) => state.trials);
  const { userModulesPermissionList } = MenuStore;
  const dispatch = useDispatch();
  const user_id = localStorage.getItem('user_id');
  const user_settings_item_id = localStorage.getItem('user_settings_item_id');
  const ui = useSelector((state) => state.ui);
  const { diseaseDashboardView } = ui;
  const { diseaseName, diseaseId, page, id, name } = useParams();
  const location = useLocation();
  const path = location.pathname;
  const pathSegments = path.split('/');
  const lastSegment = pathSegments.pop() || pathSegments.pop(); // handle trailing slash if present
  const lastWord = lastSegment.split('/').pop(); // handle trailing slash if present
  const capitalizedLastWord =
    lastWord.charAt(0).toUpperCase() + lastWord.slice(1);
  const [bookmarkBtn, setbookmarkBtn] = useState(false);
  const [bookmarkId, setbookmarkId] = useState(null);
  const [buttonYN, setButtonYN] = useState(false);
  const params = new URLSearchParams(location.search);
  let filter = params.get('filter')?params.get('filter'):'';

  // redirect to overview of the disease if user permission is not available
  const userpermissions = JSON.parse(localStorage.getItem('userpermissions'));
  if(userpermissions){
    let flag = false, noCondition = true, common_permission = false, is_superuser = false;
    userpermissions.map((item) => {
      // if is_superuser check for 
      if(item.is_superuser!== '' && item.is_superuser != null){
        common_permission = true;
        is_superuser = true;
        return true;
      }
      if(item.condition === diseaseId){
        noCondition = false;
        flag = false;
        // get current url and remove encoding
        const url = decodeURIComponent(window.location.href);
        if(url.includes('overview')){
          flag = true;
        }
        if(item.treatment !== '' && item.treatment !== null){
          if(url.includes('Treatments') || url.includes('Efficacy')){
            flag = true;
          }
        }
        if(item.trial !== '' && item.trial !== null){
          if(url.includes('Active Trials') || url.includes('All Trials') || url.includes('landmark-trials') || url.includes('TrialsByPhases')){
            flag = true;
          }
        }
        if(item.companies !== '' && item.companies !== null){
          if(url.includes('playersSummary') || url.includes('playersByCategory') || url.includes('playersByCompany') || url.includes('playersList') || url.includes('players')){
            flag = true;
          }
        }
        if(item.people !== '' && item.people !== null){
          if(url.includes('people') || url.includes('Care Team')){
            flag = true;
          }
        }
        if(item.patient_journey !== '' && item.patient_journey !== null){
          if(url.includes('Patient Journey')){
            flag = true;
          }
        }
        if(item.snapshot !== '' && item.snapshot !== null){
          if(url.includes('statisticsDashboard')){
            flag = true;
          }
        }
        if(item.report !== '' && item.report !== null){
          if(url.includes('introduction') || url.includes('symptoms') || url.includes('diagnosis') || url.includes('classification') || url.includes('Stages Categories') || url.includes('monitoring') || url.includes('treatment') || url.includes('wellness') || url.includes('innovations') || url.includes('outcomes') || url.includes('statistics') || url.includes('biology') || url.includes('genomics')){
            flag = true;
          }
        }
        
        if(item.modality !== '' && item.modality !== null){
          if(url.includes('modality')){
            common_permission = true;
            flag = true;
          }
        }
        if(item.companies !== '' && item.companies !== null){
          if(url.includes('playersSummary') || url.includes('playersByCategory') || url.includes('playersByCompany') || url.includes('playersList') || url.includes('players')){
            common_permission = true;
            flag = true;
          }
        }
        if(item.pricing !== '' && item.pricing !== null){
          if(url.includes('pricing')){
            common_permission = true;
            flag = true;
          }
        }
        if(item.physicians !== '' && item.physicians !== null){
          if(url.includes('companyByPhysicians') || url.includes('physiciansByInterventions') || url.includes('companyBySpecialization') || url.includes('physiciansByCompany') || url.includes('physiciansBySpecialization')){
            common_permission = true;
            flag = true;
          }
        }
      }
    });
    if(!is_superuser){
      if(common_permission){
        if(!flag){
          window.location.href = `/diseases`;
        }
      }else{
        if(!flag){
          window.location.href = `/diseases/${diseaseId}/${diseaseName}/overview`;
        }
        if(noCondition){
          window.location.href = `/diseases`;
        }
      }
    }
  }

    const mainNavs = {
      'Home': {
        label: <HomeIcon />,
        path: `/`,
        customStyles: {minWidth: '40px', paddingLeft: '0px', paddingRight: '0px'},
        submenu: [],
      },
      // 'Diseases': {
      //   label: 'Diseases',
      //   path: `/diseases`,
      //   submenu: [],
      // },
      // 'Companies': {
      //   label: 'Companies',
      //   path: `/AllCompanies`,
      //   submenu: ['Company']
      // },
      // 'People': {
      //   label: 'People',
      //   path: `/CEOs`,
      //   submenu: ['People'],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
      // 'Innovators': {
      //   label: 'Innovators',
      //   path: `/Innovators`,
      //   submenu: [''],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
      // 'Drug': {
      //   label: 'Products',
      //   path: `/Drug`,
      //   submenu: ['Drug'],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
      // 'Devices & Diagnostics': {
      //     label: 'Device & DX',
      //     path: `/Device`,
      //     submenu: ['Devices'],
      //     // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //     // disabled: true,
      //   },
      // 'Trials': {
      //   label: 'Trials',
      //   path: `/ActiveTrials`,
      //   submenu: ['Homepage Trials'],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
      // 'Pipeline': {
      //   label: 'Pipeline',
      //   path: `/Pipeline`,
      //   submenu: ['Pipeline'],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
      // 'Venture Capital': {
      //   label: 'VC',
      //   path: `/Investments`,
      //   submenu: ['Venture Capital'],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
      // 'Deals': {
      //   label: 'Deals',
      //   path: `/Deals`,
      //   submenu: ['Deals'],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
      // 'Public': {
      //   label: 'Public',
      //   path: `/PublicCompanies`,
      //   submenu: ['Public'],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
      // 'Competitors': {
      //   label: 'Competitors',
      //   path: `/competitors-drug`,
      //   submenu: ['Competitors'],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
      // 'Modality': {
      //   label: 'Modalities',
      //   path: `/modality`,
      //   submenu: ['Modality'],
      // },
      // 'Technology': {
      //   label: 'Technology',
      //   path: `/Technology`,
      //   submenu: ['Technology'],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
      // 'Target': {
      //   label: 'Target',
      //   path: `/Target`,
      //   submenu: ['Target'],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
      // 'AXL AI': {
      //   label: 'AXL AI',
      //   path: `/axl-ai`,
      //   submenu: ['AXL AI'],
      //   // customStyles: {cursor: 'not-allowed', opacity: '0.5'},
      //   // disabled: true,
      // },
    };

      // Dynamically map the userModulesPermissionList to create sidebar items
      if (userModulesPermissionList && userModulesPermissionList.data_portal) {
        userModulesPermissionList.data_portal.forEach(item => {
          mainNavs[item.name] = {
            label: item.name,
            path: `/${item.base_url}`,
            submenu: [''],
            customStyles: item.checked ? {} : { cursor: 'not-allowed', opacity: '0.5' },
            disabled: !item.checked, // Equivalent to item.checked === true ? false : true
          };
        });
      }

    const quickMainNavs = {
      'Home': {
        label: <HomeIcon />,
        path: `/`,
        customStyles: {minWidth: '40px', paddingLeft: '0px', paddingRight: '0px'},
        submenu: [],
      },
      'Overview': {
        label: 'Report',
        path: `/diseases/${diseaseId}/${diseaseName}/overview`,
        submenu: ['Overview'],
      },
      'Patient Journey': {
        label: 'Journey',
        path: `/diseases/${diseaseId}/${diseaseName}/Patient Journey`,
        submenu: [
          'Patient Journey',
        ],
      },
      'Companies': {
        label: 'Companies',
        path: `/diseases/${diseaseId}/${diseaseName}/playersByCategory`,
        submenu: [
          'Companies',
        ],
      },
      'Condition People': {
        label: 'People',
        path: `/diseases/${diseaseId}/${diseaseName}/CEOs`,
        submenu: [
          'Condition People',
        ],
      },
      'Approved': {
        label: 'Drugs',
        path: `/diseases/${diseaseId}/${diseaseName}/Treatments`,
        submenu: [
          'Treatment',
        ],
      },
      'Trials': {
        label: 'Trials',
        path: `/diseases/${diseaseId}/${diseaseName}/Active Trials`,
        submenu: [
          'Trials',
        ],
      },
      'Condition Venture Capital': {
        label: 'VC',
        path: `/diseases/${diseaseId}/${diseaseName}/Investments`,
        submenu: [
          'Condition Venture Capital',
        ],
      },
      'Condition Deals': {
        label: 'Deals',
        path: `/diseases/${diseaseId}/${diseaseName}/Acquisitions`,
        submenu: [
          'Condition Deals',
        ],
      },
      // 'Physicians': {
      //   label: 'Physicians',
      //   path: `/physicians`,
      //   submenu: [
      //     'Physicians',
      //   ],
      // },
      // 'People': {
      //   label: 'People',
      //   path: `/diseases/${diseaseId}/${diseaseName}/people`,
      //   submenu: [
      //     'Team',
      //   ],
      // },
      'Snapshot': {
        label: 'Snapshot',
        path: `/diseases/${diseaseId}/${diseaseName}/statisticsDashboard`,
        submenu: [
          'Snapshot',
        ],
      },
      // 'Charts': {
      //   label: 'Charts',
      //   path: `/diseases/${diseaseId}/${diseaseName}/charts`,
      //   submenu: [
      //     'Charts',
      //   ],
      // },
      'Readings': {
        label: 'Readings',
        path: `/diseases/${diseaseId}/${diseaseName}/Readings`,
        submenu: [
          'Readings',
        ],
      },
      // 'Report': {
      //   label: 'Report',
      //   path: `/diseases/${diseaseId}/${diseaseName}/introduction`,
      //   submenu: [
      //     'Introduction',
      //     'Symptoms',
      //     'Diagnosis',
      //     'Classification',
      //     'Stages',
      //     'Care Team',
      //     'Monitoring',
      //     'Report Treatment',
      //     'Quality of Life',
      //     'Innovations',
      //     'Outcomes',
      //     'Statistics',
      //     'Biology',
      //     'Genetics',
      //   ],
      // },
    };
    // remove 'Patient Journey' from quickMainNavs if diseaseId is > 119 and < 131
    if(diseaseId < 120 || diseaseId > 130){
      delete quickMainNavs['Patient Journey'];
    }
    const dynamicQuickMainNavs = {
      'Home': {
        label: <HomeIcon />,
        path: `/`,
        customStyles: {minWidth: '40px', paddingLeft: '0px', paddingRight: '0px'},
        submenu: [],
      },
      'Overview': {
        label: 'Overview',
        path: `/${page}/${id}/${name}/overview`,
        submenu: ['Overview'],
      },
      'Products': {
        label: 'Products',
        path: `/${page}/${id}/${name}/Treatments`,
        customStyles: {display: page=='Technology'?'none':''},
        submenu: [
          'Treatment',
        ],
      },
      'Trials': {
        label: 'Trials',
        path: `/${page}/${id}/${name}/Active Trials`,
        submenu: [
          'Trials',
        ],
      },
      // 'Conditions': {
      //   label: 'Conditions',
      //   path: `/${page}/${id}/${name}/conditions`,
      //   submenu: [
      //     'Conditions',
      //   ],
      // },
    };
    if(page == 'Company'){
      dynamicQuickMainNavs['Deals'] =  {
        label: 'Deals',
        path: `/${page}/${id}/${name}/Acquisitions`,
        submenu: ['Deals'],
      }
    }
    // if(page == 'Company'){
    //   dynamicQuickMainNavs['Venture Capital'] =  {
    //     label: 'VC',
    //     path: `/${page}/${id}/${name}/Investments`,
    //     submenu: [
    //       'Venture Capital',
    //     ],
    //   }
    // }
    if(page != 'Company'){
      dynamicQuickMainNavs['Companies'] = {
        label: 'Companies',
        path: `/${page}/${id}/${name}/playersByCategory`,
        submenu: [
          'Companies',
        ],
      }
    }
    if(page == 'Company'){
      dynamicQuickMainNavs['Pipeline'] =  {
        label: 'Pipeline',
        path: `/${page}/${id}/${name}/Pipeline`,
        submenu: [
          'Pipeline',
        ],
      }
    }
    if(page == 'Company'){
      dynamicQuickMainNavs['Competitors'] =  {
        label: 'Competitors',
        path: `/${page}/${id}/${name}/Competitors`,
        submenu: [
          'Competitors',
        ],
      }
    }
    if(page == 'Technology' || page == 'Target'){
      dynamicQuickMainNavs['Readings'] =  {
        label: 'Readings',
        path: `/${page}/${id}/${name}/Readings`,
        submenu: [
          'Readings',
        ],
      }
    }
    if(userpermissions){
      userpermissions.map((item) => {
        if(item.condition === diseaseId){
          if(item.treatment === '' || item.treatment === null){
            quickMainNavs['Treatment'].disabled = true;
            quickMainNavs['Treatment'].customStyles = quickMainNavs['Treatment'].customStyles || {};
            quickMainNavs['Treatment'].customStyles["cursor"] = 'not-allowed';
            quickMainNavs['Treatment'].customStyles["opacity"] = '0.5';
          }
          if(item.trial === '' || item.trial === null){
            quickMainNavs['Trials'].disabled = true;
            quickMainNavs['Trials'].customStyles = quickMainNavs['Trials'].customStyles || {};
            quickMainNavs['Trials'].customStyles["cursor"] = 'not-allowed';
            quickMainNavs['Trials'].customStyles["opacity"] = '0.5';
          }
          if(item.companies === '' || item.companies === null){
            quickMainNavs['Companies'].disabled = true;
            quickMainNavs['Companies'].customStyles = quickMainNavs['Companies'].customStyles || {};
            quickMainNavs['Companies'].customStyles["cursor"] = 'not-allowed';
            quickMainNavs['Companies'].customStyles["opacity"] = '0.5';
          }
          if(item.people === '' || item.people === null){
            quickMainNavs['People'].disabled = true;
            quickMainNavs['People'].customStyles = quickMainNavs['People'].customStyles || {};
            quickMainNavs['People'].customStyles["cursor"] = 'not-allowed';
            quickMainNavs['People'].customStyles["opacity"] = '0.5';
            // quickMainNavs['Physicians'].disabled = true;
            // quickMainNavs['Physicians'].customStyles = quickMainNavs['Physicians'].customStyles || {};
            // quickMainNavs['Physicians'].customStyles["cursor"] = 'not-allowed';
            // quickMainNavs['Physicians'].customStyles["opacity"] = '0.5';
          }
          if(item.patient_journey === '' || item.patient_journey === null){
            quickMainNavs['Patient Journey'].disabled = true;
            quickMainNavs['Patient Journey'].customStyles = quickMainNavs['Patient Journey'].customStyles || {};
            quickMainNavs['Patient Journey'].customStyles["cursor"] = 'not-allowed';
            quickMainNavs['Patient Journey'].customStyles["opacity"] = '0.5';
          }
          if(item.snapshot === '' || item.snapshot === null){
            quickMainNavs['Snapshot'].disabled = true;
            quickMainNavs['Snapshot'].customStyles = quickMainNavs['Snapshot'].customStyles || {};
            quickMainNavs['Snapshot'].customStyles["cursor"] = 'not-allowed';
            quickMainNavs['Snapshot'].customStyles["opacity"] = '0.5';
          }
          if(item.report === '' || item.report === null){
            quickMainNavs['Report'].disabled = true;
            quickMainNavs['Report'].customStyles = quickMainNavs['Report'].customStyles || {};
            quickMainNavs['Report'].customStyles["cursor"] = 'not-allowed';
            quickMainNavs['Report'].customStyles["opacity"] = '0.5';
          }
        }
      });
    }
    const quickNavs = {
      'Treatment': [
        {
          label: 'Approved',
          path: `/diseases/${diseaseId}/${diseaseName}/Treatments`,
        },
        {
          label: 'Pricing',
          path: `/diseases/${diseaseId}/${diseaseName}/PricingEfficacy`,
        },
        // {
        //   label: 'Efficacy',
        //   path: `/diseases/${diseaseId}/${diseaseName}/Efficacy`,
        // },
        // {
        //   label: 'Patents & Exclusivity',
        //   path: `/diseases/${diseaseId}/${diseaseName}/PatentsExclusivity`,
        // },
      ],
      'Trials' : [
        {
          label: 'Dashboard',
          path: `/diseases/${diseaseId}/${diseaseName}/Dashboard`,
        },
        {
          label: 'Active',
          path: `/diseases/${diseaseId}/${diseaseName}/Active Trials`,
        },
        {
          label: 'By Phases',
          path: `/diseases/${diseaseId}/${diseaseName}/TrialsByPhases`,
        },
        {
          label: 'ALL',
          path: `/diseases/${diseaseId}/${diseaseName}/All Trials`,
        },
        {
          label: 'By Location',
          path: `/diseases/${diseaseId}/${diseaseName}/Location Trials`,
        },
        {
          label: 'By Investigator',
          path: `/diseases/${diseaseId}/${diseaseName}/Investigator`,
        },
        {
          label: 'By Enrollment',
          path: `/diseases/${diseaseId}/${diseaseName}/Enrollment`,
        },
        // {
        //   label: 'Landmark',
        //   path: `/diseases/${diseaseId}/${diseaseName}/landmark-trials`,
        // },
      ],
      'Homepage Trials' : [
        {
          label: 'Active',
          path: `/ActiveTrials`,
        },
        {
          label: 'By Phases',
          path: `/TrialsByPhases`,
        },
        {
          label: 'ALL',
          path: `/AllTrials`,
        },
        {
          label: 'By Enrollment',
          path: `/Enrollment`,
        },
      ],
      'Companies' : [
        {
          label: 'All Companies',
          path: `/diseases/${diseaseId}/${diseaseName}/AllCompanies`,
        },
        {
          label: 'Companies By',
          path: `/diseases/${diseaseId}/${diseaseName}/playersByCategory`,
        },
        // {
        //   label: 'Companies By Category',
        //   path: `/diseases/${diseaseId}/${diseaseName}/playersSummary`,
        // },
        // {
        //   label: 'Single Company',
        //   path: `/diseases/${diseaseId}/${diseaseName}/playersByCompany`,
        // },
        // {
        //   label: 'All Activities',
        //   path: `/diseases/${diseaseId}/${diseaseName}/players`,
        // },
      ],
      'People' : [
        {
          label: 'CEOs',
          path: `/CEOs`,
        },
        {
          label: 'People',
          path: `/people`,
        },
        // {
        //   label: 'Investors',
        //   path: `/VC`,
        // },
        {
          label: 'Physicians',
          path: `/AllPhysicians`,
          thirdLevelLinks:['/AllPhysicians', '/companyByPhysicians', '/physiciansByCompany', '/physiciansBySpecialization']
        },
        // {
        //   label: 'Company Payments',
        //   path: `/companyByPhysicians`,
        // },
        // {
        //   label: 'Co By Interventions',
        //   path: `/physiciansByInterventions`,
        // },
        // {
        //   label: 'Co By Specialization',
        //   path: `/companyBySpecialization`,
        // },
        // {
        //   label: 'Physicians By Co',
        //   path: `/physiciansByCompany`,
        // },
        // {
        //   label: 'Physicians By Specialization',
        //   path: `/physiciansBySpecialization`,
        // },
      ],
      // 'Team' : [
      //   {
      //     label: 'Innovators',
      //     path: `/diseases/${diseaseId}/${diseaseName}/people`,
      //   },
      //   {
      //     label: 'Care Team',
      //     path: `/diseases/${diseaseId}/${diseaseName}/Care Team`,
      //   },
      // ],
      'Patient Journey' : [],
      // Reports
      'Report' : [
        {
          label: '<',
          path: `/diseases/${diseaseId}/${diseaseName}/introduction`,
        },
        {
          label: 'Introduction',
          path: `/diseases/${diseaseId}/${diseaseName}/introduction`,
        },
        {
          label: '>',
          path: `/diseases/${diseaseId}/${diseaseName}/symptoms`,
        },
      ],
      'Overview' : [
        {
          label: 'Overview',
          path: `/diseases/${diseaseId}/${diseaseName}/overview`,
        },
        {
          label: 'Science',
          path: `/diseases/${diseaseId}/${diseaseName}/science`,
        },
        {
          label: 'Orgs & Guidelines',
          path: `/diseases/${diseaseId}/${diseaseName}/orgs_guidelines`,
        },
        {
          label: 'History & People',
          path: `/diseases/${diseaseId}/${diseaseName}/people`,
        },
      ],
      'Introduction' : [
        {
          label: 'Introduction',
          path: `/diseases/${diseaseId}/${diseaseName}/introduction`,
        },
        {
          label: 'Symptoms',
          path: `/diseases/${diseaseId}/${diseaseName}/symptoms`,
        },
      ],
      'Symptoms' : [
        {
          label: 'Introduction',
          path: `/diseases/${diseaseId}/${diseaseName}/introduction`,
        },
        {
          label: 'Symptoms',
          path: `/diseases/${diseaseId}/${diseaseName}/symptoms`,
        },
        {
          label: 'Diagnosis',
          path: `/diseases/${diseaseId}/${diseaseName}/diagnosis`,
        },
      ],
      'Diagnosis' : [
        {
          label: 'Symptoms',
          path: `/diseases/${diseaseId}/${diseaseName}/symptoms`,
        },
        {
          label: 'Diagnosis',
          path: `/diseases/${diseaseId}/${diseaseName}/diagnosis`,
        },
        {
          label: 'Classification',
          path: `/diseases/${diseaseId}/${diseaseName}/classification`,
        },
      ],
      'Classification' : [
        {
          label: 'Diagnosis',
          path: `/diseases/${diseaseId}/${diseaseName}/diagnosis`,
        },
        {
          label: 'Classification',
          path: `/diseases/${diseaseId}/${diseaseName}/classification`,
        },
        {
          label: 'Stages',
          path: `/diseases/${diseaseId}/${diseaseName}/Stages Categories`,
        },
      ],
      'Stages' : [
        {
          label: 'Classification',
          path: `/diseases/${diseaseId}/${diseaseName}/classification`,
        },
        {
          label: 'Stages',
          path: `/diseases/${diseaseId}/${diseaseName}/Stages Categories`,
        },
        {
          label: 'Care Team',
          path: `/diseases/${diseaseId}/${diseaseName}/Care Team`,
        },
      ],
      'Care Team' : [
        {
          label: 'Stages',
          path: `/diseases/${diseaseId}/${diseaseName}/Stages Categories`,
        },
        {
          label: 'Care Team',
          path: `/diseases/${diseaseId}/${diseaseName}/Care Team`,
        },
        {
          label: 'Monitoring',
          path: `/diseases/${diseaseId}/${diseaseName}/monitoring`,
        },
      ],
      'Monitoring' : [
        {
          label: 'Care Team',
          path: `/diseases/${diseaseId}/${diseaseName}/Care Team`,
        },
        {
          label: 'Monitoring',
          path: `/diseases/${diseaseId}/${diseaseName}/monitoring`,
        },
        {
          label: 'Treatment',
          path: `/diseases/${diseaseId}/${diseaseName}/treatment`,
        },
      ],
      'Report Treatment' : [
        {
          label: 'Monitoring',
          path: `/diseases/${diseaseId}/${diseaseName}/monitoring`,
        },
        {
          label: 'Treatment',
          path: `/diseases/${diseaseId}/${diseaseName}/treatment`,
        },
        {
          label: 'Quality of Life',
          path: `/diseases/${diseaseId}/${diseaseName}/wellness`,
        },
      ],
      'Quality of Life' : [
        {
          label: 'Treatment',
          path: `/diseases/${diseaseId}/${diseaseName}/treatment`,
        },
        {
          label: 'Quality of Life',
          path: `/diseases/${diseaseId}/${diseaseName}/wellness`,
        },
        {
          label: 'Innovations',
          path: `/diseases/${diseaseId}/${diseaseName}/innovations`,
        },
      ],
      'Innovations' : [
        {
          label: 'Quality of Life',
          path: `/diseases/${diseaseId}/${diseaseName}/wellness`,
        },
        {
          label: 'Innovations',
          path: `/diseases/${diseaseId}/${diseaseName}/innovations`,
        },
        {
          label: 'Outcomes',
          path: `/diseases/${diseaseId}/${diseaseName}/outcomes`,
        },
      ],
      'Outcomes' : [
        {
          label: 'Innovations',
          path: `/diseases/${diseaseId}/${diseaseName}/innovations`,
        },
        {
          label: 'Outcomes',
          path: `/diseases/${diseaseId}/${diseaseName}/outcomes`,
        },
        {
          label: 'Statistics',
          path: `/diseases/${diseaseId}/${diseaseName}/statistics`,
        },
      ],
      'Statistics' : [
        {
          label: 'Outcomes',
          path: `/diseases/${diseaseId}/${diseaseName}/outcomes`,
        },
        {
          label: 'Statistics',
          path: `/diseases/${diseaseId}/${diseaseName}/statistics`,
        },
        {
          label: 'Biology',
          path: `/diseases/${diseaseId}/${diseaseName}/biology`,
        },
      ],
      'Biology' : [
        {
          label: 'Statistics',
          path: `/diseases/${diseaseId}/${diseaseName}/statistics`,
        },
        {
          label: 'Biology',
          path: `/diseases/${diseaseId}/${diseaseName}/biology`,
        },
        {
          label: 'Genetics',
          path: `/diseases/${diseaseId}/${diseaseName}/genomics`,
        },
      ],
      'Genetics' : [
        {
          label: 'Biology',
          path: `/diseases/${diseaseId}/${diseaseName}/biology`,
        },
        {
          label: 'Genetics',
          path: `/diseases/${diseaseId}/${diseaseName}/genomics`,
        },
        {
          label: 'Introduction',
          path: `/diseases/${diseaseId}/${diseaseName}/introduction`,
        },
      ],
      'Modality' : [
        {
          label: 'Drugs and Therapies',
          path: `/modality?filter=Drugs and Therapies`,
        },
        {
          label: 'Devices',
          path: `/modality?filter=devices`,
        },
        {
          label: 'Diagnostic',
          path: `/modality?filter=Diagnostic`,
        },
        {
          label: 'Vaccine',
          path: `/modality?filter=Vaccine`,
        },
        {
          label: 'Drug Delivery',
          path: `/modality?filter=Drug Delivery`,
        },
        {
          label: 'Imaging',
          path: `/modality?filter=Imaging`,
        },
      ],
      'Deals' : [
        {
          label: 'VC',
          path: `/Investments`,
          thirdLevelLinks:['/Investments', '/Firm', '/VC-Investors']
        },
        {
          label: 'M&A',
          path: `/Deals`,
        },
        {
          label: 'COMMERCIAL DEALS',
          path: `/DealsCollaborationLicensing`,
        }
      ],
      'Company' : [
        {
          label: 'My Companies',
          path: `/MyCompanies`,
        },
        {
          label: 'All Companies',
          path: `/AllCompanies`,
        },
        // {
        //   label: 'Single Company',
        //   path: `/playersByCompany?company_id=30823`,
        // },
        {
          label: 'Companies By Type',
          path: `/playersByCategory`,
        },
        {
          label: 'Public',
          path: `/PublicCompanies`,
          thirdLevelLinks:['/companyDashboard', '/transcript', '/PublicCompanies']
        },
        {
          label: 'VC',
          path: `/AllCompaniesCVC`,
        },
        // {
        //   label: 'Competitors',
        //   path: `/playersByCategory`,
        // },
      ],
      'Devices' : [
        {
          label: 'Approved (PMA)',
          path: `/Device`,
        },
        {
          label: 'Approved (510k)',
          path: `/Device510k`,
        },
        {
          label: 'Brands (Reference)',
          path: `/DeviceBrands`,
        },
        {
          label: 'Trials',
          path: `/trialDevice`,
        },
      ],
      'Drug' : [
        {
          label: 'Drugs & Therapies',
          path: `/Drug`,
          thirdLevelLinks:['/Drug', '/Brands', '/PricingEfficacy', '/trialDrugs', '/modality', '/target']
        },
        {
          label: 'Devices & Diagnostics',
          path: `/Device`,
          thirdLevelLinks:['/Device', '/Device510k', '/trialDevice', '/DeviceBrands']
        },
      ],
      'Venture Capital' : [
        {
          label: 'Investments',
          path: `/Investments`,
        },
        {
          label: 'Firm',
          path: `/Firm`,
        },
        // {
        //   label: 'Investors',
        //   path: `/VC-Investors`,
        // },
        // {
        //   label: 'CEOs',
        //   path: `/CEOs`,
        // },
      ],
      'Public' : [
        {
          label: 'Dashboard',
          path: `/companyDashboard`,
        },
        {
          label: 'Transcript',
          path: `/transcript`,
        },
        {
          label: 'Company List',
          path: `/PublicCompanies`,
        }
      ],
      'Competitors' : [
        {
          label: 'Drugs',
          path: `/CompetitorsDrug`,
          thirdLevelLinks:['/CompetitorsDrug', '/CompetitorsTrialsDrug']
        },
        {
          label: 'Devices',
          path: `/CompetitorsDevice`,
          thirdLevelLinks:['/CompetitorsDevice', '/CompetitorsTrialsDevice']
        },
        {
          label: 'Trials',
          path: `/CompetitorsTrials`,
        }
      ],
      'Condition Venture Capital' : [
        {
          label: 'Investments',
          path: `/diseases/${diseaseId}/${diseaseName}/Investments`,
        },
        // {
        //   label: 'Investors',
        //   path: `/diseases/${diseaseId}/${diseaseName}/VC`,
        // },
        {
          label: 'Firm',
          path: `/diseases/${diseaseId}/${diseaseName}/Firm`,
        },
        // {
        //   label: 'CEOs',
        //   path: `/diseases/${diseaseId}/${diseaseName}/CEOs`,
        // },
      ],
      'Condition People' : [
        {
          label: 'CEOs',
          path: `/diseases/${diseaseId}/${diseaseName}/CEOs`,
        },
        // {
        //   label: 'Investors',
        //   path: `/diseases/${diseaseId}/${diseaseName}/VC`,
        // },
        {
          label: 'Physicians',
          path: `/diseases/${diseaseId}/${diseaseName}/Physicians`,
        },
        {
          label: 'Co By Physicians',
          path: `/diseases/${diseaseId}/${diseaseName}/companyByPhysicians`,
        },
        {
          label: 'Co By Interventions',
          path: `/diseases/${diseaseId}/${diseaseName}/physiciansByInterventions`,
        },
        {
          label: 'Co By Specialization',
          path: `/diseases/${diseaseId}/${diseaseName}/companyBySpecialization`,
        },
        // {
        //   label: 'Physicians By Co',
        //   path: `/diseases/${diseaseId}/${diseaseName}/physiciansByCompany`,
        // },
        // {
        //   label: 'Physicians By Specialization',
        //   path: `/diseases/${diseaseId}/${diseaseName}/physiciansBySpecialization`,
        // },
      ],
    }

    if(diseaseId == 130){
      quickNavs['Treatment'].push({
        label: 'Revenue',
        path: `/diseases/${diseaseId}/${diseaseName}/M/Revenue`,
      })
    }

    const dynamicQuickNavs = {
      // 'Treatment': [
      //   {
      //     label: 'Approved',
      //     path: `/${page}/${id}/${name}/Treatments`,
      //   },
      // ],
      'Trials' : [
        {
          label: 'Active',
          path: `/${page}/${id}/${name}/Active Trials`,
        },
        {
          label: 'By Phases',
          path: `/${page}/${id}/${name}/TrialsByPhases`,
        },
        {
          label: 'ALL',
          path: `/${page}/${id}/${name}/All Trials`,
        },
      ],
      'Companies' : [
        {
          label: 'Companies By',
          path: `/${page}/${id}/${name}/playersByCategory`,
        },
        // {
        //   label: 'Companies By Category',
        //   path: `/${page}/${id}/${name}/playersSummary`,
        // }
      ],
      'Deals' : [
        {
          label: 'VC',
          path: `/${page}/${id}/${name}/Investments`,
        },
        {
          label: 'Deals',
          path: `/${page}/${id}/${name}/Acquisitions`,
        }
      ],
      'Competitors' : [
        {
          label: 'All',
          path: `/${page}/${id}/${name}/Competitors`,
        },
        {
          label: 'Drugs',
          path: `/${page}/${id}/${name}/CompetitorsDrugs`,
        },
        {
          label: 'Devices',
          path: `/${page}/${id}/${name}/CompetitorsDevices`,
        },
        {
          label: 'Trials',
          path: `/${page}/${id}/${name}/CompetitorsTrials`,
        },
      ],
    }

    // if(userpermissions){
    //   userpermissions.map((item) => {
    //     if(item.condition === diseaseId){
    //       if(item.report !== '' && item.report !== null){
    //         quickNavs['Treatment'].push({
    //             label: 'Report: Approved',
    //             path: `/diseases/${diseaseId}/${diseaseName}/treatment`,
    //           },
    //           {
    //             label: 'Report: Quality of Life',
    //             path: `/diseases/${diseaseId}/${diseaseName}/wellness`,
    //         });
    //       }else{
    //         quickNavs['Treatment'].push({
    //           label: 'Report: Approved',
    //           path: `/diseases/${diseaseId}/${diseaseName}/treatment`,
    //           customStyles: {cursor: 'not-allowed', opacity: '0.5'},
    //           disabled: true,
    //         },
    //         {
    //           label: 'Report: Quality of Life',
    //           path: `/diseases/${diseaseId}/${diseaseName}/wellness`,
    //           customStyles: {cursor: 'not-allowed', opacity: '0.5'},
    //           disabled: true,
    //       });
    //       }
    //     }
    //   });
    // }

    const handleClickBookmark = () =>{
      setbookmarkBtn(!bookmarkBtn)
      setButtonYN(true)
      if(!bookmarkBtn){
        let data = {
          user:user_id,
          bookmark_id:id?id:diseaseId,
          bookmark_type:page?page:'Condition',
          user_settings_item:user_settings_item_id
        }
        bookmarkDiseasesPost(data)
        .then((res) => {
          setbookmarkId(res.data.id)
          setButtonYN(false)
        })
        .catch((error) => {
          console.log(error);
        });
      }else{
        let data = {
          bookmark_id:bookmarkId
        }
        bookmarkDiseasesDelete(data)
        .then((res) => {
          setButtonYN(false)
        })
        .catch((error) => {
          console.log(error);
        });
      }
    }

    const getBookmark = () => {
      let data = {
        user:user_id,
        bookmark_id:id?id:diseaseId,
        bookmark_type:page?page:'Condition',
        user_settings_item_id:user_settings_item_id
      }
      bookmarkDiseasesChecked(data)
      .then((res) => {
        if(res.data){
          setbookmarkId(res.data[0].id)
          setbookmarkBtn(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }

    useEffect(() => {
      if(user_id && (page || diseaseName)){
        getBookmark();
      }
    }, []);

  // add 'Overview' label to all the keys in quickNavs
  // Object.keys(quickNavs).forEach((key) => {
  //   // skip if key is 'Overview'
  //   // if (key === 'Overview') return;
  //   quickNavs[key].unshift({
  //     label: <HomeIcon />,
  //     path: `/diseases/${diseaseId}/${diseaseName}/overview`,
  //     customStyles: {minWidth: '40px', paddingLeft: '0px', paddingRight: '0px'},
  //   });
  // });
  return (
    <CCard>
      <CCardBody>
        <div className='row'>
          <div className='col-sm-6'>
            {/* <Breadcrumbs aria-label='breadcrumb' separator='>' id='breadcrumb'>
              <Link
                underline='always'
                color='inherit'
                to='/'
                id='homeButtonBreadcrumb'
              >
                Home
              </Link>

              {diseaseName && (
                <Link
                  underline='always'
                  color='inherit'
                  to={`/diseases/${diseaseId}/${diseaseName}/overview`}
                >
                  {diseaseName}
                </Link>
              )}

              <Typography color='textPrimary'>{capitalizedLastWord}</Typography>
            </Breadcrumbs> */}
            {mainNav == 'condition' && (
              <CLabel>
                <button disabled={`${buttonYN ? 'disabled' : ''}`} onClick={handleClickBookmark} style={{minWidth:'40px'}} className={`btn btn-link p-0 m-0 pb-2 ${bookmarkBtn ? 'active' : ''}`}>
                  {bookmarkBtn?<BookmarkIcon style={{color:'#2bb670'}}/>:<BookmarkBorderIcon style={{color:'#2bb670'}}/>}
                </button>
              </CLabel>
            )}
            {label !== 'Contact' && label !== 'Comment' && (
              <CLabel>
                <h3>
                  {diseasesName && (
                    <strong>{diseasesName}&nbsp;:&nbsp;</strong>
                  )}
                </h3>
              </CLabel>
            )}

            <CLabel>
              <h3>{label}</h3>
            </CLabel>
          </div>
        </div>
        <div>
          {page ? dynamicQuickMainNavs && mainNav === 'condition' && (
            Object.keys(dynamicQuickMainNavs).map((key) => (
              <Button variant='contained'
                className={dynamicQuickMainNavs[key].submenu.indexOf(quickNavItem) > -1 ? 'btn-quick-nav active': 'btn-quick-nav'}
                style={dynamicQuickMainNavs[key].customStyles}
                component={Link}
                to={dynamicQuickMainNavs[key].path}
                onClick={dynamicQuickMainNavs[key].onClick}
                disabled={dynamicQuickMainNavs[key].disabled}
              >
                {dynamicQuickMainNavs[key].label}
              </Button>
            ))
          ) : quickMainNavs && mainNav === 'condition' && (
            Object.keys(quickMainNavs).map((key) => (
              <Button variant='contained'
                className={quickMainNavs[key].submenu.indexOf(quickNavItem) > -1 ? 'btn-quick-nav active': 'btn-quick-nav'}
                style={quickMainNavs[key].customStyles}
                component={Link}
                to={quickMainNavs[key].path}
                onClick={quickMainNavs[key].onClick}
                disabled={quickMainNavs[key].disabled}
              >
                {quickMainNavs[key].label}
              </Button>
            ))
          )}
          {mainNavs && mainNav === 'homepage' && (
            Object.keys(mainNavs).map((key) => (
              <Button variant='contained'
                className={mainNavs[key].submenu.indexOf(quickNavItem) > -1 ? 'btn-quick-nav active': 'btn-quick-nav'}
                style={mainNavs[key].customStyles}
                component={Link}
                to={mainNavs[key].path}
                onClick={mainNavs[key].onClick}
                disabled={mainNavs[key].disabled}
              >
                {mainNavs[key].label}
              </Button>
            ))
          )}
        </div>
        <div>
        {page && dynamicQuickNavs[quickNavItem] && (
            dynamicQuickNavs[quickNavItem].map((item) => (
              <Button variant='contained' 
                className={(path === item.path ? 'btn-quick-nav active': 'btn-quick-nav') + (mainNav ? ' submenu' : '' )}
                style={item.customStyles}
                component={Link}
                to={item.path}
                onClick={item.onClick}
                disabled={item.disabled}
              >
                {item.label}
              </Button>
            ))
          )}
          {!page && quickNavs[quickNavItem] && (
            quickNavs[quickNavItem].map((item) => (
              <Button variant='contained' 
                className={((item?.thirdLevelLinks && item.thirdLevelLinks.includes(path)) || path === item.path || filter.toLowerCase() === item.label.toLowerCase() ? 'btn-quick-nav active': 'btn-quick-nav') + (mainNav ? ' submenu' : '' )}
                style={item.customStyles}
                component={Link}
                to={item.path}
                onClick={item.onClick}
                disabled={item.disabled}
                style={{textTransform: item.label === 'CEOs' ? 'inherit' : ''}}
              >
                {item.label}
              </Button>
            ))
          )}
        </div>
      </CCardBody>
    </CCard>
  );
};

export default Header;
