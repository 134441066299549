import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingWidget from '../../containers/formField/LoadingWidget';
import { getCompanyPipelineData } from '../../apiCalls/axiosCall/reportsAxios';
import { useParams, useLocation } from 'react-router-dom';
import Header from './Header';
import GeneralTable from './GeneralTable';
import StaticLoadingWidget from '../../containers/formField/StaticLoadingWidget';
import AccordionSearchBar from './reusableComponents/AccordionSearchBar';
import AccordionCheckFilters from './reusableComponents/AccordionCheckFilters';
import { getPurpleBookReducer } from '../../reducers/TrialsReducer';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { capitalizeFirstLetter } from './Helper';
import HeaderPage from './HeaderPage';

const AllCompetitorsDrug = ({ hideHeader, searchValue }) => {
  const ProductsStore = useSelector((state) => state.trials);
  const { purpleBookList, getCount } = ProductsStore;
  const [financials, setFinancials] = useState({});
  const [searchVal, setSearch] = useState(searchValue ? searchValue : '');
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState('');
  const [axlCategory, setAxlCategory] = useState('');
  const [axlGenericClass, setAxlGenericClass] = useState('');
  const [axlModality, setAxlModality] = useState('');
  const [condition, setCondition] = useState('');
  const [axlPhase, setAxlPhase] = useState('');
  const [axlTarget, setAxlTarget] = useState('');
  const dispatch = useDispatch();
  const { page, name, id } = useParams();
  const decodedName = decodeURIComponent(name);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let search = params.get('search') ? params.get('search') : '';
  let [checkBox, setCheckBox] = useState([
    { key: 'Yes', value: false, label: 'Include Withdrawn/Discontinued' },
  ]);
  let [checkBoxData, setcheckBoxData] = useState('');
  let [checkBoxsCompanyType, setCheckBoxsCompanyType] = useState([
    { key: 'All', value: false, label: 'All' },
    { key: 'Company', value: true, label: 'Company' },
    { key: 'University,Hospital', value: false, label: 'University/Hospital' },
  ]);
  let [stringCompanyType, setStringCompanyType] = useState('Company');
  const options = {
    leafModalData: {},
    trialType: 'competitors',
    staticLoading: ProductsStore.staticLoading,
    // columns:{'name':'','axl_generic':'Generic Name','axl_company':'','brand_status':'','axl_brand_RoA':''},
    columns: {
      product_name: 'Product',
      axl_generic_class: 'Generic Class',
      indication: '',
      company: '',
      revenue_current: '',
      year: '',
    },
    showChips: true,
    // Key is the Location of Chip. Value is the which column show in the chips.
    chipColumn: {
      axl_generic_class: 'axl_generic_class',
      axl_brand_RoA: 'axl_brand_RoA',
      indication: 'indication',
    },
    disableClick: true,
  };

  const updateCheckBox = (newItems, checkKeys) => {
    setCheckBox(newItems);
    setcheckBoxData(checkKeys);
    // setOffset(0)
  };

  const updateCheckBoxs = (newItems, checkKeys) => {
    const keywords = ['all'];
    const allPresent = keywords.every((keyword) =>
      checkKeys.toLowerCase().includes(keyword)
    );
    if (allPresent) {
      let updatedNewItems = newItems.map((item) => ({ ...item, value: true }));
      let updatedCheckKeys = newItems.map((item) => item.key).join(',');
      setCheckBoxsCompanyType(updatedNewItems);
      setStringCompanyType(updatedCheckKeys);
    } else {
      setCheckBoxsCompanyType(newItems);
      setStringCompanyType(checkKeys);
    }
    // setOffset(0)
  };

  // console.log(purpleBookList)

  const apiCallRequest = (data) => {
    // setCompany(data)
    // const searchItems = {
    //   filter_by_3 : data['axl_company_name'],
    //   is_leaf : true,
    // }
    // dispatch(GetPlayersAllDispatchLevelLeaf('all', searchItems));
  };

  const findTrials = (val) => {
    let searchItems = {
      search: val,
      limit: 500,
      offset: 0,
      axl_category: axlCategory,
      axl_generic_class: axlGenericClass,
      axl_modality: axlModality,
      axl_condition: condition,
      axl_company: company,
      axl_target: axlTarget,
      checkbox_string: !hideHeader ? checkBoxData : 'Yes',
      checkbox_string_company_type: !hideHeader ? stringCompanyType : '',
    };
    setSearch(val);
    dispatch(getPurpleBookReducer(searchItems));
  };

  const filterCompany = [
    'Alnylam',
    'Arrowhead',
    'Ionis Pharma',
    'BioNTech',
    'Dicerna',
    'Moderna',
    'Novartis',
    'ProQR',
    'Regulus',
    'Sarepta Therapeutics',
    'Silence',
    'Wave Lifesciences',
    'Stoke',
    'Dyne Therapeutics',
  ];

  let searchItems = {
    search: searchVal,
    search_type: 'exact',
    limit: 500,
    offset: 0,
    axl_category: axlCategory,
    axl_generic_class: axlGenericClass,
    axl_modality: axlModality,
    axl_condition: condition,
    axl_company: company,
    axl_target: axlTarget,
    checkbox_string: !hideHeader ? checkBoxData : 'Yes',
    checkbox_string_company_type: !hideHeader ? stringCompanyType : '',
  };
  useEffect(() => {
    if (search) {
      setSearch(search);
    }
    dispatch(getPurpleBookReducer(searchItems));
  }, [
    name,
    id,
    dispatch,
    axlCategory,
    axlGenericClass,
    axlModality,
    condition,
    company,
    axlTarget,
    checkBox,
    stringCompanyType,
  ]);

  const filterCategoryOptions =
    purpleBookList &&
    purpleBookList['filter'] &&
    purpleBookList['filter']['distinct_intervention_category'].map((cls) => {
      return capitalizeFirstLetter(cls['axl_intervention_category']);
    });

  const filterModalityOptions =
    purpleBookList &&
    purpleBookList['filter'] &&
    purpleBookList['filter']['distinct_modality'].map((cls) => {
      return capitalizeFirstLetter(cls['axl_modality']);
    });

  const filterConditionOptions =
    purpleBookList &&
    purpleBookList['filter'] &&
    purpleBookList['filter']['distinct_condition'].map((cls) => {
      return capitalizeFirstLetter(cls['indication']);
    });

  const filterGenericClassOptions =
    purpleBookList &&
    purpleBookList['filter'] &&
    purpleBookList['filter']['distinct_axl_generic_classes'].map((cls) => {
      return capitalizeFirstLetter(cls['axl_generic_class']);
    });

  const filterCompanyOptions =
    purpleBookList &&
    purpleBookList['filter'] &&
    purpleBookList['filter']['distinct_company'].map((cls) => {
      return capitalizeFirstLetter(cls['company']);
    });

  const filterTargetOptions =
    purpleBookList &&
    purpleBookList['filter'] &&
    purpleBookList['filter']['distinct_target'].map((cls) => {
      return capitalizeFirstLetter(cls['axl_target_name']);
    });

  return (
    <>
      {options.staticLoading ? <StaticLoadingWidget /> : ''}
      {!hideHeader && (
        <Header
          label='Competitors'
          diseasesName=''
          quickNavItem='Competitors'
          mainNav='homepage'
        />
      )}
      {!hideHeader && <h3>Competitors</h3>}
      {!hideHeader && (
        <div class='row'>
          <HeaderPage label='' quickNavItem='CompetitorsDrug' mainNav='' />
        </div>
      )}
      {!hideHeader && (
        <div class='row mb-1'>
          <div class='col-12'>
            <AccordionSearchBar
              searchVal={searchVal}
              findTrials={findTrials}
              tableRowCount={getCount && getCount}
            />
          </div>
          <div class='col-12'>
            <AccordionCheckFilters
              checkBoxs={checkBoxsCompanyType}
              updateCheckBoxs={updateCheckBoxs}
            />
            <span style={{ position: 'absolute', right: '0' }}>
              <AccordionCheckFilters
                checkBoxs={checkBox}
                updateCheckBoxs={updateCheckBox}
              />
            </span>
          </div>
        </div>
      )}
      {/* Autocomplete filters */}
      {!hideHeader && (
        <>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                id='intervention-type-autocomplete'
                freeSolo
                className='filter-autocomplete'
                options={filterCategoryOptions || []}
                getOptionLabel={(option) => option}
                style={{ width: '100%' }}
                value={axlCategory || ''}
                onChange={(e, value) => setAxlCategory(value ? value : '')}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    label='Intervention Category'
                    placeholder='Search...'
                    InputLabelProps={{
                      style: { fontSize: '1rem' },
                    }}
                    {...params}
                  />
                )}
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                id='modality-autocomplete'
                freeSolo
                className='filter-autocomplete'
                options={filterModalityOptions || []}
                getOptionLabel={(option) => option}
                style={{ width: '100%' }}
                value={axlModality || ''}
                onChange={(e, value) => setAxlModality(value ? value : '')}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    label='Modality'
                    placeholder='Search...'
                    {...params}
                  />
                )}
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                id='static-autocomplete'
                freeSolo
                className='filter-autocomplete'
                options={filterGenericClassOptions || []}
                getOptionLabel={(option) => option}
                style={{ width: '100%' }}
                value={axlGenericClass || ''}
                onChange={(e, value) => setAxlGenericClass(value ? value : '')}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    label='Generic Class'
                    placeholder='Search...'
                    {...params}
                  />
                )}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                id='condition-autocomplete'
                freeSolo
                className='filter-autocomplete'
                options={filterConditionOptions || []}
                getOptionLabel={(option) => option}
                style={{ width: '100%' }}
                value={condition || ''}
                onChange={(e, value) => setCondition(value ? value : '')}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    label='Condition'
                    placeholder='Search...'
                    {...params}
                  />
                )}
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                id='trials-phases-autocomplete'
                freeSolo
                className='filter-autocomplete'
                options={filterCompanyOptions || []}
                getOptionLabel={(option) => option}
                style={{ width: '100%' }}
                value={company || ''}
                onChange={(e, value) => setCompany(value ? value : '')}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    label='Company'
                    placeholder='Search...'
                    {...params}
                  />
                )}
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                id='target-autocomplete'
                freeSolo
                className='filter-autocomplete'
                options={filterTargetOptions || []}
                getOptionLabel={(option) => option}
                style={{ width: '100%' }}
                value={axlTarget || ''}
                onChange={(e, value) => setAxlTarget(value ? value : '')}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    label='Target'
                    placeholder='Search...'
                    {...params}
                  />
                )}
              />
            </div>
          </div>
        </>
      )}

      {loading ? (
        <div style={{ marginTop: '20%' }}>
          <LoadingWidget />{' '}
        </div>
      ) : (
        <div className={hideHeader != true ? 'card p-3' : ''}>
          {purpleBookList && purpleBookList.data.length > 0 && (
            <GeneralTable
              financials={purpleBookList.data}
              options={options}
              apiCallRequest={apiCallRequest}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AllCompetitorsDrug;
