import React, { Component, lazy, Suspense, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import store from './store.js';

import './scss/style.scss';
import { Provider } from 'react-redux';
import Page404 from './views/pages/Page404.js';
import Trials from './views/docterPatients/Trials.js';
import TrialsDatabase from './views/docterPatients/TrialsDatabase.js';
import FeedbackWidget from './views/docterPatients/FeedbackWidget/FeedbackWidget';

require('dotenv').config();

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);

// Containers
const TheLayout = lazy(() => import('./containers/TheLayout'));

// Pages
const Login = lazy(() => import('./views/pages/login/Login'));
const Register = lazy(() => import('./views/pages/register/Register'));
const Page500 = lazy(() => import('./views/pages/page500/Page500'));
const ListDiseases = lazy(() => import('./views/docterPatients/ListDiseases/ListDiseases'));
const Verification = lazy(() =>
  import('./views/pages/verification/verificationlink')
);
const ResetPassword = lazy(() =>
  import('./views/pages/verification/resetpassword')
);
const ForgotPassword = lazy(() =>
  import('./views/pages/forgotPass/ForgotPassword')
);
const Emailverification = lazy(() =>
  import('./views/pages/verification/emailverification')
);

const App = () => {
  const [isSessionActive, setIsSessionActive] = useState(
    localStorage.getItem('token')
  );

  const checkSessionStatus = (sessionFlag) => {
    setIsSessionActive({ isSessionActive: sessionFlag });
  };

  const isLogin = isSessionActive;
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Switch>
            {/* <Route
              exact
              path='/listDiseases'
              // name="Login Page"
              render={(props) =>
                isLogin ? <ListDiseases {...props} /> : <Redirect to='/login' />
              }
            /> */}
            <Route
              exact
              path='/login'
              name='Login Page'
              render={(props) => (
                <Login {...props} checkSessionStatus={checkSessionStatus} />
              )}
            />
            <Route
              exact
              path='/register'
              name='Register Page'
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path='/verify-user'
              name='Verification'
              render={(props) => <Verification {...props} />}
            />
            <Route
              exact
              path='/reset-password'
              name='Reset Password'
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path='/forgot-password'
              name='Forgot Password'
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path='/verify-email'
              name='Email Verify'
              render={(props) => <Emailverification {...props} />}
            />
            <Route
              exact
              path='/404'
              name='Page 404'
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path='/500'
              name='Page 500'
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path='/trials'
              name='Trials'
              render={(props) => <TrialsDatabase />}
            />
            <Route
              path='/'
              name='Home'
              render={(props) =>
                isLogin ? <TheLayout {...props} /> : <Redirect to='/login' />
              }
            />
          </Switch>
        </Suspense>
        <FeedbackWidget />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
