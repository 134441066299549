import React, { useState, useEffect, useRef } from 'react';
import './FeedbackWidget.scss'; // Import the CSS file
import html2canvas from 'html2canvas';
import { useDispatch } from 'react-redux';
import { getUserFeedbackDispatch } from '../../../reducers/UiReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner, faCommentDots } from '@fortawesome/free-solid-svg-icons';

const FeedbackWidget = () => {
    const user_id = localStorage.getItem('user_id');
    const [isOpen, setIsOpen] = useState(false);
    const [comment, setComment] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const textareaRef = useRef(null);

    const handleOpen = () => {
        setIsOpen(true);
        setIsSubmitted(false);
    };

    const handleClose = () => setIsOpen(false);

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            handleClose();
        }
    };

    const handleTextareaKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'Enter' && comment.trim() !== '') {
            handleSubmit(e);
        }
    };

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
            if (textareaRef.current) {
                textareaRef.current.focus(); // Focus the textarea when dialog opens
            }
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const currentUrl = window.location.href;

        console.log('Feedback Message:', comment);
        console.log('Current URL:', currentUrl);

        try {
            // Hide the feedback widget
            const feedbackElement = document.querySelector('.feedback-widget-container');
            if (feedbackElement) {
                feedbackElement.style.display = 'none';
            }

            // Capture screenshot
            const canvas = await html2canvas(document.body);
            const screenshot = canvas.toDataURL('image/png');

            // Show the feedback widget again
            if (feedbackElement) {
                feedbackElement.style.display = 'block';
            }

            await dispatch(getUserFeedbackDispatch({ comment, url: currentUrl, screenshot, user: user_id }))
                .then(() => {
                    setComment('');
                    setIsSubmitted(true);
                    setTimeout(() => {
                        handleClose();
                    }, 5000);
                })
                .catch((error) => {
                    console.error('Error sending feedback:', error);
                    alert('An error occurred. Please try again.');
                });
        } catch (error) {
            console.error('Error sending feedback:', error);
            alert('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const isAdminPage = window.location.href.includes('/admin');

    return (
        <div className="feedback-widget-container">
            <div className="feedback-widget">
                <button className="feedback-icon-button" onClick={handleOpen}>
                    <FontAwesomeIcon icon={faCommentDots} size="lg" />
                </button>
                {isOpen && (
                    <div className="feedback-dialog">
                        <div className="dialog-content">
                            <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={handleClose} />
                            {isLoading ? (
                                <div className="loading-icon">
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                </div>
                            ) : isSubmitted ? (
                                <div className="success-message">
                                    <span role="img" aria-label="check">✔️</span> Feedback submitted successfully!
                                </div>
                            ) : (
                                <>
                                    <h3>Feedback</h3>
                                    <form onSubmit={handleSubmit}>
                                        <textarea
                                            ref={textareaRef}
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                            onKeyDown={handleTextareaKeyDown}
                                            placeholder="Enter your feedback here"
                                            required
                                        />
                                        <div className="button-container">
                                            <button type="submit">Send</button>
                                            <span className="shortcut-hint">Ctrl + Enter to send</span>
                                        </div>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FeedbackWidget;
